<template>
  <div id="doctorHome" class="w-full ">
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/5"></div>
     
        <vx-card
          class="
             vx-col md:w-1/3 
            text-center
            cursor-pointer
            backgroundShadow"
          :style="propBG1"
        >
          <div class="vx-row">
            <div class="w-full mt-3 mt-20 mb-10">
              <h3 style="color: #454a62" class="text-center">
                {{ $t("YourPatients") }}
              </h3>
            </div>
          </div>

          <div class="vx-row ml-3" style="margin-top: 5%; margin-bottom: 12%">
            <div class="w-full">
              <vs-button
                class="mr-2 customizer-btn rounded-lg text-sm"
                style="width: 95% !important"
                @click="getYourPaient()"
              >
                <img src="@/assets/images/doctor/arrowIcon.svg" alt="" />
              </vs-button>
            </div>
          </div>
        </vx-card>
        <vx-card
          class="
             vx-col md:w-1/3 
            text-center
            cursor-pointer
            backgroundShadow
          "
          :style="propBG2"
        >
          <div class="vx-row">
            <div class="w-full mt-3 mt-20 mb-10">
              <h3 style="color: #454a62" class="text-center">
                {{ $t("OnlineAppointment") }}
              </h3>
            </div>
          </div>

          <div class="vx-row ml-3" style="margin-top: 5%; margin-bottom: 12%">
            <div class="w-full">
              <vs-button
                class="mr-2 customizer-btn rounded-lg text-sm"
                style="width: 95% !important"
                @click="showOnlineAppointMent"
              >
                <img src="@/assets/images/doctor/arrowIcon.svg" alt="" />
              </vs-button>
            </div>
          </div>
        </vx-card>
    
      
    </div>

    <!-- <div v-if="windowWidth>= 1200">
      <h2 style="font-weight: bold; position: absolute; left: 10%; top: 62%">
        {{ $t("ShortAccess") }}
      </h2>
    </div> -->
    <div class="mt-10 pb-5">
      <div class="vx-row">
        <div class="vx-col md:w-1/4"></div>

        <vs-button
          class="vx-col lg:w-1/4 m-3 w-full customizer-btn rounded-lg text-sm"
          @click="addYourAvailability()"
          >{{ $t("addYourAvailability") }}
        </vs-button>
        
        <vs-button
          class="vx-col lg:w-1/4 m-3 w-full customizer-btn rounded-lg text-sm"
          @click="editYourAvailability()"
          >{{ $t("editYourAvailability") }}
        </vs-button>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/4"></div>
        <vs-button
          class="vx-col lg:w-1/4 m-3 w-full customizer-btn rounded-lg text-sm"
          @click="getPendingTask()"
          >{{ $t("PendingTask") }}
        </vs-button>

        <vs-button
          class="vx-col lg:w-1/4 m-3 w-full customizer-btn rounded-lg text-sm"
          @click="showDoctorWeeklyAppointment=true"
          >{{ $t("thisWeekOnlineAppointments") }}
        </vs-button>
      </div>

  <div class="vx-row " v-if="$store.state.AppActiveUser.Doctor.InHospital">
        <div class="vx-col md:w-1/4" ></div>
         <vs-button
          class="vx-col lg:w-1/4 m-3 w-full customizer-btn rounded-lg text-sm"
          color="primary"
          @click="SurgeriesTreatments()"
          >{{ $t("SurgeriesTreatments") }}
        </vs-button>
       
      </div>
    </div>
    <!-- <vs-popup
      fullscreen
      title=""
      :active.sync="showOnlineAppointmentfield"
    >
      <showOnlineAppointmentCom :doctorDoc="doctorDoc"
        v-if="showOnlineAppointmentfield"
      ></showOnlineAppointmentCom>
    </vs-popup>-->
    <vs-popup
      title=""
      :active.sync="showDoctorWeeklyAppointment"
    >
      <DoctorWeeklyAppointments 
        v-if="showDoctorWeeklyAppointment"
        @closePop="showDoctorWeeklyAppointment=false"
      ></DoctorWeeklyAppointments>
    </vs-popup>
  </div>
</template>

<script>

import DoctorWeeklyAppointments from "@/views/doctor/Appointment/DoctorWeeklyAppointments.vue"
import modulePatient from "@/store/Patient/modulePatient.js";


export default {
  components: {
    DoctorWeeklyAppointments
    // vSelect
    //showOnlineAppointmentCom,
  },
  data() {
    return {
      search:{},
      showDoctorWeeklyAppointment:false,
      doctorDoc: {},
      // showOnlineAppointmentfield: false,
      propBG1: {
        backgroundImage: `url(${require("@/assets/images/doctor/card1.png")}) `,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      propBG2: {
        backgroundImage: `url(${require("@/assets/images/doctor/card2.png")})`,
      
         backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      arrowIcon: {
        backgroundImage: `url(${require("@/assets/images/doctor/arrowIcon.svg")})`,
        backgroundRepeat: "no-repeat"
      },
    };
  },

  methods: {
  editYourAvailability(){
 this.$router.push({ name: "DoctorEditAvailability"});
    },
    addYourAvailability(){
 this.$router.push({ name: "DoctorAddAvailability"});
    },

    getPendingTask() {
      //this.doctorDoc.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;
      debugger;
      //this.showOnlineAppointmentfield=true;
      this.$router.push({
        name: "pendingTaskDoctor",
        params: { ID: this.$store.state.AppActiveUser.Doctor.ID },
      });
    },
       SurgeriesTreatments() {
      this.$router.push({ name:"ReservedSurgery" });
    },
    showOnlineAppointMent() {
      this.$router.push({
        name: "onlineAppointment",
        params: { ID: this.$store.state.AppActiveUser.Doctor.ID },
      });
    },
    getYourPaient() {
      debugger;
      var id = this.$store.state.AppActiveUser.Doctor.ID;
      this.$router.push({ name: "YourPatient", params: { ID: id } });
    },
    addNewData() {
      this.$router.push({ name: "PatientEdit" });
    },
    editData(ID) {
      this.$router.push({ name: "PatientEdit", params: { ID: ID } });
    },

    deleteData() {
      debugger;
      this.$vs.loading();
      this.$store
        .dispatch("patientList/DeletePatient", this.rowDataForDelete.ID)
        .then(() => {
          this.searchData();
          this.$vs.loading.close();
          window.showDeleteSuccess();
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$vs.loading.close();
            window.showDeleteFailed(err.response.data.message);
          } else {
            this.$vs.loading.close();
            window.showDeleteFailed("");
          }
        });
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.deleteData,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    searchData() {
      this.$store
        .dispatch("patientList/SearchPatients", this.search)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },

  created() {
    if (!modulePatient.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
   
    this.searchData();
  },
  mounted() {
    this.isMounted = true;
  },
  computed:{
     windowWidth() {
      return this.$store.state.windowWidth;
    },
  }
};
</script>
<style scoped>
#doctorHome  .backgroundShadow {
 background-color: transparent;
  box-shadow: rgb(0 0 0 / 20%) 5px 5px 15px;
  box-shadow: rgb(0 0 0 / 0%) 5px 5px 15px;
}


#doctorHome .vs-button {
  margin-bottom: 20px !important;
}
#doctorHome .text-sm {
  font-size: 0.95rem !important;
  font-weight: 600;
}
#doctorHome h3 {
  font-weight: bold;

  font-size: 1.7rem !important;
}
</style>
